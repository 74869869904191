/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { ButtonAction, Button } from "@components/Button"
import { splitText } from "@utils"

const GoodToGreatPage = ({ data }) => {
  const { aboutJKImage, coverImage, whatWeLearnedImage, workExperienceImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "ungated_download", { event_category: "download", event_label: "glassdoor_report" })
    window.open("/documents/jk-good-to-great-whitepaper.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Good To Great" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container">
            <div className="flex justify-center">
              <div className="lg:w-10/12">
                <div className="pt-clamp-14-25 pb-clamp-8-16">
                  <h1
                    className="text-clamp-40-80"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-teal-light": "Unlock insights to boost your company’s Glassdoor scores",
                        "text-white": "with our FREE report.",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center lg:pb-18">
              <div className="lg:w-10/12">
                <div className="flex flex-col-reverse grid-cols-10 lg:grid">
                  <div className="col-span-6 mt-14 lg:pr-18 lg:mt-0">
                    <div className="relative flex">
                      <div className="absolute inset-0 flex lg:hidden">
                        <div className="mt-auto aspect-w-667 aspect-h-80 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
                      </div>
                      <div data-aos="fade-right">
                        <GatsbyImage
                          className="relative"
                          objectFit="contain"
                          image={getImage(coverImage)}
                          alt="Getting from good to great"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="lg:pt-18" data-aos="fade-left">
                      <p className="text-white">Begin your journey to becoming a top-ranked employer on Glassdoor.</p>
                      <div className="mt-10">
                        <ButtonAction func={downloadGuide} className="p-5 text-gray-darkest bg-teal-light">
                          Get the free guide
                        </ButtonAction>
                      </div>
                    </div>
                    r
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <div className="container">
            <div className="flex justify-center py-clamp-17-28">
              <div className="grid-cols-10 lg:grid lg:w-10/12">
                <div data-aos="fade-up" className="col-span-8">
                  <h3 className="text-clamp-25-35">
                    Want to improve your company’s rankings on Glassdoor but don’t know where to start? Our
                    comprehensive report is designed to help you go from ‘good’ to ‘great’ on Glassdoor.
                  </h3>
                  <p className="mt-12">
                    We used an AI-driven comparative language analysis platform to uncover what separates the top-ranked
                    organizations from the mid-ranked ones. This powerful research, compiled into a comprehensive
                    report, decodes what drives positive employee ratings for the leading employers on Glassdoor.
                  </p>
                  <div className="mt-16">
                    <h4>You’ll learn:</h4>
                    <ul className="mt-4 ml-16">
                      <li>How employees at top companies describe a positive work experience.</li>
                      <li>The impact that strong, authentic leadership has on Glassdoor rankings.</li>
                      <li>The cultural aspects that drive positive employee feedback.</li>
                      <li>What kinds of career growth opportunities lead to more satisfied employees.</li>
                      <li>And much more!</li>
                    </ul>
                  </div>
                  <div className="mt-16">
                    <p>
                      Whether you’re a seasoned HR professional or just starting out, this report provides the
                      evidence-based insights you need to drive your strategy and advocate for additional support for
                      key initiatives that drive employee engagement and happiness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="container">
              <div className="grid gap-4 grid-col-1 lg:grid-cols-2">
                <div>
                  <GatsbyImage
                    className="relative"
                    objectFit="contain"
                    image={getImage(whatWeLearnedImage)}
                    alt="What We Learned"
                  />
                </div>
                <div>
                  <GatsbyImage
                    className="relative"
                    objectFit="contain"
                    image={getImage(workExperienceImage)}
                    alt="Work Experience"
                  />
                </div>
              </div>
            </div>
            <div className="container mt-20">
              <div className="flex justify-center">
                <div className="grid grid-cols-10 lg:w-10/12">
                  <div className="col-span-8">
                    <h3>Get the guide now!</h3>
                    <p className="mt-6 lg:mt-14">
                      Download our FREE report now and begin your journey to becoming a top-ranked employer on
                      Glassdoor.
                    </p>
                    <ButtonAction func={downloadGuide} className="mt-10 lg:mt-12 text-gray-darkest">
                      Download now
                    </ButtonAction>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-24 lg:mt-0">
            <div className="lg:container">
              <div className="flex justify-center">
                <div className="flex flex-col-reverse grid-cols-10 gap-x-4 lg:grid lg:w-10/12">
                  <div className="relative col-start-1 col-end-11 row-start-1 row-end-2 lg:mt-28">
                    <div className="w-full h-full bg-teal-light extend before:bg-teal-light"></div>
                  </div>
                  <div className="container relative col-span-6 col-start-2 row-start-1 row-end-2 lg:max-w-full lg:px-0 py-clamp-12-19 lg:mt-28">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-right" className="relative sm:pr-18">
                      <h3 className="text-clamp-35-62">Want to tell your best story?</h3>
                      <p className="mt-clamp-9-16">
                        Are you struggling to tell your best, most authentic story to your people? We’re here to help.
                        At JK, storytelling is our superpower. Get in touch with us today!
                      </p>

                      <Button link="/contact-us" className="text-gray-darkest mt-clamp-11-17">
                        Contact us
                      </Button>
                    </div>
                  </div>
                  <div className="container relative col-start-8 col-end-11 row-start-1 row-end-2 pr-0 lg:max-w-full lg:px-0">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 mt-auto h-1/2 lg:hidden sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-left" className="lg:min-w-204">
                      <GatsbyImage
                        className="relative"
                        objectFit="contain"
                        image={getImage(aboutJKImage)}
                        alt="About JK"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default GoodToGreatPage

export const GoodToGreatQuery = graphql`
  query goodToGreatQuery {
    aboutJKImage: file(relativePath: { eq: "good-to-great/tell-your-best-story.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    coverImage: file(relativePath: { eq: "good-to-great/cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    whatWeLearnedImage: file(relativePath: { eq: "good-to-great/what-we-learned.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    workExperienceImage: file(relativePath: { eq: "good-to-great/work-experience.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
